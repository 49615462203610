import axios from 'axios';
import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  onAuthStateChanged,
  Persistence,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from 'firebase/auth';

// import dotenv from 'dotenv';
import { doc, DocumentReference, getDoc, initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';

type FirestoreRole = 'Customer Service' | 'Warehouse' | 'Operator' | 'Router' | 'Business Manager' | 'Accounting' | 'Customer';
export interface FirestoreUser {
  name: string;
  roles: FirestoreRole[];
}

// dotenv.config();

const firebaseConfig = JSON.parse(String(process.env.REACT_APP_FIREBASE_CONFIG));
console.log(firebaseConfig);
// Your web app's Firebase configuration
// export const firebaseConfig = {
//   apiKey: 'AIzaSyDdDvCHEcrk0GSTZhu_xpUIUkKXXz7561k',
//   authDomain: 'hyperway-firebase.firebaseapp.com',
//   projectId: 'hyperway-firebase',
//   storageBucket: 'hyperway-firebase.appspot.com',
//   messagingSenderId: '819864246726',
//   appId: '1:819864246726:web:875599d620b40de3f8bac7',
// };

const app = initializeApp(firebaseConfig);
const firestore = initializeFirestore(app, { ignoreUndefinedProperties: true });

const auth = getAuth(app);
const functions = getFunctions(app);
const storage = getStorage(app);

const functionInstance = axios.create({
  baseURL:
    // process.env.NODE_ENV === 'development'
    //   ? 'http://localhost:5001/palmify-dump-truck/us-central1/api'
    //   :
    'https://us-central1-palmify-dump-truck.cloudfunctions.net/api',
});

export const AuthContext = createContext<{ user: User; error: Error; loading: boolean; firestoreUser: FirestoreUser } | undefined>(
  undefined
);
auth.onAuthStateChanged(
  async (e) => {
    // console.log(e);
    if (e) {
    }
  },
  (e) => {
    console.log(e);
  }
);

export const AuthContextProvider = (props: any) => {
  const [user, setUser] = useState<User | null>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(true);
  const [firestoreUser, setFirestoreUser] = useState<FirestoreUser | null>();

  const auth = getAuth();
  // console.log(auth);
  // console.log(auth.currentUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (e) => {
        setUser(e);
        // console.log(e);
        if (e) {
          const user = await getDoc(doc(firestore, 'users', e.uid) as DocumentReference<FirestoreUser>);
          setFirestoreUser(user.data());
        }
        setLoading(false);
      },
      (e) => {
        setError(e);
        setLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);
  return <AuthContext.Provider value={{ user, error, loading, firestoreUser }} {...props} />;
};

const handleLogin = async ({
  email,
  password,
  rememberMe = true,
  successCallback,
  errorCallback,
}: {
  email: string;
  password: string;
  rememberMe?: boolean;
  successCallback: (userCredential: UserCredential) => void;
  errorCallback: (error: any) => void;
}) => {
  // https://firebase.google.com/docs/auth/web/auth-state-persistence
  const persistance = browserLocalPersistence;
  setPersistence(auth, persistance)
    .then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    })
    .then(successCallback)
    .catch(errorCallback);
};
const handleLogout = ({ successCallback, errorCallback }: { successCallback: () => void; errorCallback: (error: any) => void }) => {
  signOut(auth).then(successCallback).catch(errorCallback);
};

const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: !!auth?.user };
};

// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
//   console.log('connected Firestore Emulator');
//   // connectFirestoreEmulator(firestore, 'localhost', 8080);
//   // console.log('connected Function Emulator');
// }

export { firestore, auth, handleLogin, handleLogout, useAuthState, functionInstance, functions, storage };
